import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import classes from './CategoryCascad.module.css'
import { CategoriesLayout } from '../../layouts/CategoriesLayout/CategoriesLayout'

export const CategoryCascad = ({
  heading,
  categoryId,
  hasHeader,
  isHeading,
  count = 6,
}) => (
  <SkeletonTheme>
    <CategoriesLayout
      heading={heading}
      categoryId={categoryId}
      hasHeader={hasHeader}
    >
      <div className={isHeading ? classes.headings : classes.events}>
        {Array.from({ length: count }).map((_, index) => (
          <Skeleton key={index} className={classes.card} />
        ))}
      </div>
    </CategoriesLayout>
  </SkeletonTheme>
)
