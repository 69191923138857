import React, { useMemo } from 'react'
import Layout from '@/layouts/MainLayout'
import { CATEGORIES, QUIZ } from '../services/constans'
import SEO from '@/components/seo'
import { Button } from '../components'
import { useInfiniteQuery } from 'react-query'
import { navigate } from 'gatsby'
import { CategoryCascad } from '../cascad-screens/CategoryCascad'
import { LecturesMedia } from '../components'
import getLectures from '../utils/getLectures'

const getCategory = id => CATEGORIES[+id - 1]

function Lectures({ params, location }) {
  const category = useMemo(() => params['*'], [params])
  const isQuiz = useMemo(() => category === QUIZ, [category])

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
  } = useInfiniteQuery(['lectures', location.pathname, category], getLectures, {
    getNextPageParam: lastPage => lastPage.next,
    onError: () => navigate('/404'),
  })

  return (
    <Layout location={location} category={category}>
      <SEO title={'Лекции'} />
      {isFetching ? (
        <CategoryCascad heading={'Лекции'} categoryId={category} count={30} />
      ) : (
        <LecturesMedia
          heading={'Лекции'}
          lectures={data?.pages}
          categoryId={category}
          isQuiz={isQuiz}
        />
      )}
      {!isQuiz && hasNextPage && (
        <Button
          style={{ maxWidth: '190px', margin: '0 auto 100px' }}
          theme='secondary'
          onClick={() => {
            hasNextPage && fetchNextPage()
          }}
          disabled={!hasNextPage || isFetchingNextPage}
        >
          Показать еще
        </Button>
      )}
    </Layout>
  )
}
export default Lectures
